<template>
  <div class="page-404 page-dashboard">
    <div class="d-flex d-flex-justify-between">
      <div class="text-center main-content">
        <h1 class="mt5">Not Found!</h1>

        <h4>
          {{ "Sorry, the page you requested is nowhere to be found." }}
        </h4>

        <img
          class="mt3 mb3"
          :src="`${publicPath}assets/404.svg`"
          height="200"
          alt="404"
        />

        <br />
        <h3>
          <el-button type="warning" @click="goToDashboard">
            <strong>{{ "Go to my Dashboard" }}</strong>
          </el-button>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "not-found",
  components: {},
  methods: {
    goToDashboard() {
      this.$router.push("/dashboard/saved/all");
    },
  },
};
</script>
<style lang="scss" scoped>
.main-content {
  width: 100%;
}
a {
  color: $color-base;
  cursor: pointer;
}
</style>
